<template>
  <v-container fluid class="pa-0 pt-16 mt-12">
    <H1Intro v-if="text.h1intro"
             :title="text.h1intro.title" :text="text.h1intro.text"/>
    <Gallery v-if="galleryData && galleryData.path && galleryData.noOfImages"
             :path="galleryData.path" :no-of-images="galleryData.noOfImages" background="#A9C141"
             :passed-class="'mt-16'"
             :title="galleryData.title" :captions="galleryData.captions"/>

    <TwoColorDividers lower-color="#1f6437" upper-color="#ffbc0d"
                      :passed-class="!$vuetify.breakpoint.xs ? 'mt-16 pt-11' : ''"/>
    <ConversionTeaserBig background-color="#d90007" v-if="$store.state.text.modules.conversionTeaserBig"
                         :button-text="$store.state.text.modules.conversionTeaserBig.button"
                         :title="$store.state.text.modules.conversionTeaserBig.title"
                         :text="$store.state.text.modules.conversionTeaserBig.text"
    />
    <RestaurantKonzepte/>
    <TwoColorDividers upper-color="#a9c141" lower-color="#d90007"/>
  </v-container>
</template>

<script>
import H1Intro from "@/components/common/H1Intro";
import TwoColorDividers from "@/components/common/TwoColorDividers";
import ConversionTeaserBig from "@/components/common/ConversionTeaserBig";
import Gallery from "@/components/common/Gallery";
import RestaurantKonzepte from "../common/RestaurantKonzepte";

export default {
  name: "PageWithGallery",
  props: ['moduleName', 'threeCards', 'gallery'],
  components: {
    RestaurantKonzepte,
    Gallery,
    ConversionTeaserBig,
    TwoColorDividers,
    H1Intro,
  },
  metaInfo: function () {
    return {
      title: this.text.metaTitle ? this.text.metaTitle : '',
      meta: [
        {name: 'description', content: this.text.metaDescription ? this.text.metaDescription : ''}
      ]
    }
  },
  mounted() {
    this.$store.commit('setCustomSubject', null);
  },
  data: () => ({}),
  computed: {
    text() {
      return this.$store.state.text[this.moduleName];
    },
    galleryData() {
      return this.$store.state.text[this.gallery];
    }
  }
}
</script>

<style scoped>

</style>