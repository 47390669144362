var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{class:_vm.passedClass + (_vm.$vuetify.breakpoint.smAndDown ? ' pb-0': ''),attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{staticClass:"max-width-1168",attrs:{"cols":"12","md":"10"}},[_c('v-col',{class:'text-center pa-0 ' + _vm.columnClass,attrs:{"cols":"12"}},[_c('p',{staticClass:"mb-0 title-size",domProps:{"innerHTML":_vm._s(_vm.title)}}),_c('v-row',{staticClass:"pt-10",attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{class:{
          'pl-0 pr-2 pb-0': _vm.$vuetify.breakpoint.mdAndUp,
          'pl-2 pr-2 pt-7': _vm.$vuetify.breakpoint.smAndDown,
        },attrs:{"cols":"12","sm":"4"}},[_c('img',{attrs:{"src":_vm.stamp1}})]),_c('v-col',{class:{
          'pl-1 pr-1 pb-0': _vm.$vuetify.breakpoint.mdAndUp,
          'pl-2 pr-2 pt-7': _vm.$vuetify.breakpoint.smAndDown,
        },attrs:{"cols":"12","sm":"4"}},[_c('img',{attrs:{"src":_vm.stamp2}})]),_c('v-col',{class:{
          'pl-2 pr-0 pb-0': _vm.$vuetify.breakpoint.mdAndUp,
          'pl-2 pr-2 pt-7': _vm.$vuetify.breakpoint.smAndDown,
        },attrs:{"cols":"12","sm":"4"}},[_c('img',{attrs:{"src":_vm.stamp3}})])],1),(_vm.button)?_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{staticClass:"pa-3",attrs:{"cols":"3","sm":"3"}},[_c('button',{staticClass:"mdButton",domProps:{"innerHTML":_vm._s(_vm.button)},on:{"click":function($event){return _vm.open(_vm.link)}}})])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }