<template>
  <v-row :class="moduleCss" :style="moduleStyle" justify="center">
    <v-col cols="12" v-if="title" class="pt-16 mt-7 white--text title-size text-center mb-10" v-html="title"/>
    <CoolLightBox
        :items="items.images"
        :index="index"
        @close="index = null">
    </CoolLightBox>

    <v-carousel hide-delimiters :class="{
        'max-width-1168': !$vuetify.breakpoint.mobile,
        'max-width-mobile': $vuetify.breakpoint.mobile,
      }"
                :height="!$vuetify.breakpoint.mobile ? '520' : 'auto'" style="margin-bottom:37px;">
      <v-carousel-item
          v-for="(item,i) in items.thumbs"
          :key="i"
          :src="item"
          @click="index = i"
      ></v-carousel-item>
    </v-carousel>
  </v-row>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'

export default {
  name: "Gallery",
  components: {CoolLightBox},
  props: ['background', 'passedClass', 'path', 'noOfImages', 'title', 'captions'],
  data: function () {
    return {
      index: null
    };
  },
  computed: {
    items() {
      let imagesArray = [];
      let thumbs = [];
      for (let i = 1; i <= this.noOfImages; i++) {
        if (this.captions && this.captions.length > 1) {
          imagesArray.push({src: this.path + i + '.jpg', title: this.captions[i - 1] ? this.captions[i - 1] : ''})
        } else {
          imagesArray.push({src: this.path + i + '.jpg'})
        }
        thumbs.push(this.path + i + '_thumb.jpg')
      }
      return {images: imagesArray, thumbs: thumbs};
    },
    moduleCss() {
      const common = 'pb-16';
      if (this.passedClass) {
        return common + ' ' + this.passedClass;
      }
      return common;
    },
    moduleStyle() {
      const common = '';
      if (this.background) {
        return common + 'background-color: ' + this.background;
      }
      return common;
    }
  }
}
</script>

<style scoped>
.max-width-mobile {
  width: 100%
}
</style>