<template>
  <v-row no-gutters justify="center" :class="passedClass ? passedClass : ''">
    <v-col cols="12" class="max-width-1168">
      <v-card class="main-card" v-if="!$vuetify.breakpoint.mobile" :height="'auto'">
        <v-img :src="image ? image : herocontentimage" eager/>
        <v-card flat :class="'tile-card pa-5 ' + cardPosition" >
          <p class="title-size pr-5 pl-5 pt-5" v-html="title"/>
          <p class="card-text text-size pr-5 pl-5" v-html="text"/>
          <v-card-actions v-if="button">
            <button class="mdButton" v-html="button" @click="open(link)"/>
          </v-card-actions>
        </v-card>
      </v-card>
      <v-card class="main-card pa-5" v-else flat>
        <v-img :src="image ? image : herocontentimage" contain eager/>
        <v-card-title v-html="title" style="font-family: 'Speedee Bold'"/>
        <v-card-text v-html="text"/>
        <v-card-actions v-if="button">
          <button class="mdButton" v-html="button" @click="open(link)"/>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import herocontentimage from "@/assets/img/3_McDrive_Instore_FutureConcepts/MCD_McDrive_Big_Teaser_01.jpg"

export default {
  name: "HeroContent",
  props: ['title', 'text', 'position', 'image', 'passedClass', 'button', 'link'],
  data: () => ({
    herocontentimage: herocontentimage
  }),
  mounted() {
  },
  methods: {
    open(link) {
      window.open(link, '_self')
    },
  },
  computed: {
    cardPosition() {
      if (!this.position) {
        return 'tile-card-right';
      } else if (this.position === 'right') {
        return 'tile-card-right';
      } else if (this.position === 'left') {
        return 'tile-card-left';
      } else if (this.position === 'center') {
        return 'tile-card-center';
      } else {
        return 'tile-card-right';
      }
    }
  },
}
</script>

<style scoped>
.main-card {
  position: relative;
  width: 100%;
}

p {
  color: #000 !Important;
}

.tile-card {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 33.5%;
}

.tile-card-left {
  left: 4%;
}

.tile-card-right {
  right: 4%;
}

.tile-card-center {
  left: 33.1%;
}

@media only screen and (min-width: 600px) and (max-width: 715px) {
  .title-size {
    font-size: 16px;
    line-height: 16px;
  }

  .text-size {
    font-size: 11px;
    line-height: 14px;
  }
}

@media only screen and (min-width: 716px) and (max-width: 799px) {
  .title-size {
    font-size: 18px;
    line-height: 16px;
  }

  .text-size {
    font-size: 13px;
    line-height: 14px;
  }
}

@media only screen and (min-width: 800px) and (max-width: 1136px) {
  .title-size {
    font-size: 22px;
    line-height: 16px;
  }

  .text-size {
    font-size: 13px;
    line-height: 14px;
  }
}

@media only screen and (min-width: 1137px) and (max-width: 1199px) {
  .title-size {
    font-size: 22px;
    line-height: 18px;
  }

  .text-size {
    font-size: 16px;
    line-height: 14px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1266px) {
  .text-size {
    font-size: 16px;
    line-height: 20px;
  }
}

@media only screen and (min-width: 1267px) and (max-width: 1323px) {
  .title-size {
    font-size: 28px;
    line-height: 27px;
  }
}

@media only screen and (min-width: 1324px) and (max-width: 1470px) {
  .text-size {
    line-height: 24px;
  }
}

.v-card__title {
  word-break: unset ;
}
</style>
