import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import HomePage from "@/components/Pages/HomePage";
import PageWithGallery from "../components/Pages/PageWithGallery";
import PageWithPanorama from "../components/Pages/PageWithPanorama";

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomePage
    },
    {
        path: '/aktuelles',
        name: 'Aktuelles',
        component: () => import(/* webpackChunkName: "about" */ '../components/Pages/AktuellesPage.vue')
    },
    {
        path: '/standorte',
        name: 'Standorte',
        component: () => import(/* webpackChunkName: "about" */ '../components/Pages/StandortePage.vue')
    },
    {
        path: '/restaurantkonzepte/mcdrive',
        name: 'McDrive',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../components/Pages/McDrivePage.vue')
    },
    {
        path: '/restaurantkonzepte/instore',
        name: 'Instore',
        component: () => import(/* webpackChunkName: "about" */ '../components/Pages/InstorePage.vue')
    },
    {
        path: '/restaurantkonzepte/zukunftige',
        name: 'Zukunftige',
        component: () => import(/* webpackChunkName: "about" */ '../components/Pages/ZukunftigePage.vue')
    },
    {
        path: '/unternehmen',
        name: 'Unternehmen',
        component: () => import(/* webpackChunkName: "about" */ '../components/Pages/UnternehmenPage.vue')
    },
    {
        path: '/kontakt',
        name: 'Kontakt',
        component: () => import(/* webpackChunkName: "about" */ '../components/Pages/ContactPage.vue')
    },
    {
        path: '/test',
        name: 'test',
        component: () => import(/* webpackChunkName: "about" */ '../components/Pages/test.vue')
    },
    {
        path: '/dresden/bildergalerie-restaurant-wilsdrufferstrasse.html',
        name: 'DresdenGallery',
        component: PageWithGallery,
        props: {moduleName: "dresden", threeCards: 'threeCards', gallery: 'dresdenGallery'},
    },
    {
        path: '/berlin/bildergalerie-restaurant-zooterrassen.html',
        name: 'BerlinGallery',
        component: PageWithGallery,
        props: {moduleName: "berlin", threeCards: 'threeCards', gallery: 'berlinGallery'},
    },
    {
        path: '/hamburg/bildergalerie-restaurant-eiffestrasse.html',
        name: 'HamburgGallery',
        component: PageWithGallery,
        props: {moduleName: "hamburg", threeCards: 'threeCards', gallery: 'hamburgGallery'},
    },
    {
        path: '/kiefersfelden/panoramatour-restaurant-mcdrive.html',
        name: 'KiefersfeldenTour',
        component: PageWithPanorama,
        props: {moduleName: "kiefersfelden", threeCards: 'threeCards'},
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        document.getElementById('app').scrollIntoView();
    }
})

export default router
