<template>
  <v-row no-gutters justify="center">
    <v-col :cols="$vuetify.breakpoint.mobile ? 10 : 12">
      <v-card width="100%" class="pa-0 ma-auto" :style="$vuetify.breakpoint.mobile ? 'max-width: 584px' : ''">
        <v-row no-gutters v-if="!switched">
          <v-col cols="12" :md="$vuetify.breakpoint.mobile ? 0 : 6" class="pa-0">
            <v-img :src="image"   width="100%" class="border-radius-left" aspect-ratio=""/>
          </v-col>
          <v-col cols="12" :md="$vuetify.breakpoint.mobile ? 0 : 6" :class="textCss">
            <h1 v-html="title" class="mt-10 px-8" :class="{
            'mt-10': !criticlaWidth,
            'pl-8': !criticlaWidth,
          }"/>
            <p v-html="text" class="px-8" :class="{
            'mt-5': true,
            'pl-8': !criticlaWidth,
            'pr-8': !criticlaWidth,
          }"/>
            <p class="pl-8 mb-10">
              <button class="mdButton" v-html="button" @click="redirect(link)"/>
            </p>
          </v-col>
        </v-row>
        <v-row no-gutters v-else>
          <v-col cols="12" :md="$vuetify.breakpoint.mobile ? 0 : 6" :class="textCss">
            <h1 v-html="title" :class="{
            'mt-10': !criticlaWidth,
            'pl-8': !criticlaWidth,
          }"/>
            <p v-html="text" :class="{
            'mt-5': true,
            'pl-8': !criticlaWidth,
            'pr-8': !criticlaWidth,
          }"/>
            <p class="pl-8">
              <button class="mdButton" v-html="button" @click="redirect(link)"/>
            </p>
          </v-col>
          <v-col cols="12" md="6" class="pa-0">
            <v-img :src="image" eager contain width="100%" class="border-radius-left"/>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "HorizontalCard",
  props: ['image', 'title', 'text', 'button', 'switched', 'link'],
  data: () => ({
    criticlaWidth: false,
  }),
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  mounted() {
    this.isCriticalWidth(window.innerWidth);
  },
  methods: {
    redirect(link, external) {
      if (external) {
        window.open(link, '_blank');
      } else {
        this.$router.push({name: link})
      }
    },
    myEventHandler(e) {
      const width = e.target.innerWidth;
      this.isCriticalWidth(width);
    },
    isCriticalWidth(width) {
      if (width > 1028) {
        this.criticlaWidth = false;
      } else {
        this.criticlaWidth = true;
      }
    }
  },
  computed: {
    textCss() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return '';
      } else {
        return 'pl-16 pt-0 pr-16';
      }
    }
  }
}
</script>

<style scoped>
.border-radius-left {
  border-radius: 4px 0 0 4px;
}

h1 {
  color: #2E2E2E;
  font-family: "Speedee Bold";
  font-size: 36px;
  font-weight: bold;
  letter-spacing: -0.15px;
  line-height: 40px;
}
</style>
