<template>
  <v-footer class="font-weight-medium mt-5" color="white">
    <v-col class="text-center" cols="12">
      <v-row justify="center">
        <v-col
          cols="12"
          sm="2"
          md="2"
          :class="{
            'pa-0': true,
            'text-right': $vuetify.breakpoint.mdAndUp,
            'pb-5': $vuetify.breakpoint.xs
          }"
        >
          <a
            :href="text.footer.datenschutz.link"
            class="footer"
            target="_blank"
            >{{ text.footer.datenschutz.name }}</a
          >
        </v-col>
        <v-col
          cols="6"
          sm="4"
          md="2"
          :class="{
            'pa-0': true,
            'text-center': $vuetify.breakpoint.smAndDown,
            'pb-7': $vuetify.breakpoint.xs
          }"
        >
          <a
            :href="text.footer.impressumUndAgb.link"
            class="footer"
            target="_blank"
            >{{ text.footer.impressumUndAgb.name }}</a
          >
        </v-col>
        <v-col
          cols="6"
          sm="3"
          md="2"
          :class="{
            'pa-0 text-left': true,
            'pb-7 text-center': $vuetify.breakpoint.xs
          }"
          v-if="onetrust"
        >
          <a class="footer" @click="showOneTrust" target="_blank">{{
            text.footer.cookie.name
          }}</a>
        </v-col>
        <v-col cols="12" sm="7" md="5" :class="{ 'footer pt-0': true }">
          <v-img
            :src="logo"
            alt="Mc Donald's"
            width="17"
            class="d-inline-block mr-2"
          />
<!--          {{ text.footer.allRights }}-->
          © {{ getCurrentYear() }} McDonald‘s. Alle Rechte vorbehalten.
        </v-col>
      </v-row>
    </v-col>
  </v-footer>
</template>

<script>
import logo from "@/assets/img/logo.svg";

export default {
  name: "Footer",
  data() {
    return {
      logo,
      onetrust: true
    };
  },
  methods: {
    showOneTrust() {
      window.OneTrust.ToggleInfoDisplay();
    },
    getCurrentYear() {
      // Get the current year using JavaScript's Date object
      const currentYear = new Date().getFullYear();
      return currentYear;
    }
  },
  computed: {
    text() {
      return this.$store.state.text;
    }
  }
};
</script>

<style scoped>
.footer {
  color: #292929;
  text-decoration: none;
  font-weight: 500 !important;
}
</style>
