<template>
  <DynamicCards :cards-data="text" :images="cardsImages" :border="true"
                :passed-class="{
                    'mt-4 pt-6 mt-16 pb-6 mb-16':true,
                    'pr-5 pl-5': $vuetify.breakpoint.mobile
                  }"
                height="480"
                passed-text-class="dynamicCards1"
  />
</template>

<script>
import card1 from "@/assets/img/0_Global/MCD_McDrive_Third_Teaser_01.jpg";
import card2 from "@/assets/img/0_Global/MCD_Instore_Third_Teaser_01.jpg";
import card3 from "@/assets/img/0_Global/MCD_FutureConcepts_Third_Teaser_01.jpg";
import DynamicCards from "./DynamicCards";

export default {
  name: "RestaurantKonzepte",
  components: {DynamicCards},
  data: () => ({
    cardsImages: [
      card1,
      card2,
      card3
    ],
  }),
  computed: {
    text() {
      return this.$store.state.text.restaurantKonzepte;
    }
  },
}
</script>

<style scoped>

</style>
