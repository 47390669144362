<template>
  <v-row no-gutters class="pl-5 pr-5 mt-3 positon-relative">
    <v-col cols="12" v-if="!$vuetify.breakpoint.mobile && !belowCriticalWidth">
      <v-card flat tile>
        <v-img :src="image" eager contain class="intro-image"/>
        <v-card-text class="position-absolute intro">
          <H1Intro :title="text.title" :text="text.text" :intro="true" :remove-top-padding="removeIntroTopPadding"/>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" v-else>
      <H1Intro :title="text.title" :text="text.text" :intro="true"/>
    </v-col>
  </v-row>
</template>

<script>
import H1Intro from "@/components/common/H1Intro";

export default {
  name: "Intro",
  components: {H1Intro},
  props: ['image'],
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  methods: {
    myEventHandler(e) {
      const width = e.target.innerWidth;
      if (width < 1325) {
        this.belowCriticalWidth = true;
      }
      if (width > 1325 && width < 1701) {
        this.removeIntroTopPadding = true;
      } else {
        this.removeIntroTopPadding = false;
      }
    }
  },
  mounted() {
    if (window.innerWidth < 1325) {
      this.belowCriticalWidth = true;
    }
    if (window.innerWidth > 1325 && window.innerWidth < 1701) {
      this.removeIntroTopPadding = true;
    } else {
      this.removeIntroTopPadding = false;
    }
  },
  data: () => ({
    belowCriticalWidth: false,
    removeIntroTopPadding: false,
  }),
  computed: {
    text() {
      return this.$store.state.text.home.intro;
    },
  }
}
</script>

<style scoped>
.intro-image {
  width: 100%;
}

.intro {
  width: 60%;
  left: 3%;
  top: 48%;
}
</style>