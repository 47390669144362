import Vue from 'vue';
import App from './App.vue';
import axios from 'axios'
import VueAxios from 'vue-axios'
import vuetify from './plugins/vuetify';
import store from './store';
import '@/assets/css/app.css';
import '@/assets/css/vendor/iconfonts.css';
import router from './router'
Vue.config.productionTip = false
Vue.use(VueAxios, axios)

new Vue({
    vuetify,
    store,
    router,
    render: h => h(App)
}).$mount('#app')
