var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-footer',{staticClass:"font-weight-medium mt-5",attrs:{"color":"white"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{class:{
            'pa-0': true,
            'text-right': _vm.$vuetify.breakpoint.mdAndUp,
            'pb-5': _vm.$vuetify.breakpoint.xs
          },attrs:{"cols":"12","sm":"2","md":"2"}},[_c('a',{staticClass:"footer",attrs:{"href":_vm.text.footer.datenschutz.link,"target":"_blank"}},[_vm._v(_vm._s(_vm.text.footer.datenschutz.name))])]),_c('v-col',{class:{
            'pa-0': true,
            'text-center': _vm.$vuetify.breakpoint.smAndDown,
            'pb-7': _vm.$vuetify.breakpoint.xs
          },attrs:{"cols":"6","sm":"4","md":"2"}},[_c('a',{staticClass:"footer",attrs:{"href":_vm.text.footer.impressumUndAgb.link,"target":"_blank"}},[_vm._v(_vm._s(_vm.text.footer.impressumUndAgb.name))])]),(_vm.onetrust)?_c('v-col',{class:{
            'pa-0 text-left': true,
            'pb-7 text-center': _vm.$vuetify.breakpoint.xs
          },attrs:{"cols":"6","sm":"3","md":"2"}},[_c('a',{staticClass:"footer",attrs:{"target":"_blank"},on:{"click":_vm.showOneTrust}},[_vm._v(_vm._s(_vm.text.footer.cookie.name))])]):_vm._e(),_c('v-col',{class:{ 'footer pt-0': true },attrs:{"cols":"12","sm":"7","md":"5"}},[_c('v-img',{staticClass:"d-inline-block mr-2",attrs:{"src":_vm.logo,"alt":"Mc Donald's","width":"17"}}),_vm._v(" © "+_vm._s(_vm.getCurrentYear())+" McDonald‘s. Alle Rechte vorbehalten. ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }