<template>
  <v-app>
    <template v-if="textLoaded">
      <Header/>
      <router-view/>
      <Footer/>
    </template>
  </v-app>
</template>
<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import axios from "axios";

export default {
  name: 'App',

  components: {Footer, Header},

  data: () => ({
    //
  }),
  computed: {
    textLoaded() {
      return this.$store.state.text !== null;
    },
  },
  mounted() {
    if (this.$route.fullPath.includes('/hamburg/bildergalerie-restaurant-eiffestra%C3%9Fe.html')) {
      this.$router.push({name: 'HamburgGallery'});
    }
    axios.get(this.getUrl()).then(r =>
        this.$store.commit('setText', r.data[this.$store.state.lang]));
  },
  methods: {
    getUrl() {
      return process.env.VUE_APP_LANG_URL
    }
  },
};
</script>