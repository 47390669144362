<template >
  <div :class="passedClass ? passedClass : ''">
    <template v-if="!switched">
      <v-row no-gutters>
        <v-col cols="12" class="upper-bar" :style="'background-color: '+ upperColor"></v-col>
      </v-row>
      <v-row no-gutters class="mt-0">
        <v-col cols="12" class="lower-bar" :style="'background-color: '+ lowerColor"></v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row no-gutters>
        <v-col cols="12" class="lower-bar" :style="'background-color: '+ lowerColor"></v-col>
      </v-row>
      <v-row no-gutters class="mt-0">
        <v-col cols="12" class="upper-bar" :style="'background-color: '+ upperColor"></v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
export default {
  name: "TwoColorDividers",
  props: ['upperColor', 'lowerColor', 'passedClass', 'switched']
}
</script>

<style scoped>
.upper-bar {
  height: 30px;
}

.lower-bar {
  height: 10px;
}
</style>
