<template>
  <div :class="passedClass ? passedClass : ' mb-0'">
    <v-row no-gutters v-if="title">
      <v-col cols="12" class="mb-8 mt-6 text-center">
        <p class="title-size" v-html="title"/>
      </v-col>
    </v-row>
    <v-row no-gutters :class="passedClass ? passedClass : ''">
      <v-col cols="12" v-if="image">
        <v-img :src="image" eager contain class="fieldMapImage"/>
      </v-col>
    </v-row>
    <v-row no-gutters v-if="text" class="mt-7" justify="center">
      <v-col cols="11" md="7" :class="textCss">
        <p v-html="text" class="text-size "/>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: "ParagraphImageVertical",
  props: ['title', 'image', 'text', 'passedClass'],
  computed: {
    textCss() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return '';
      } else {
        return 'text-center pl-10 pr-2';
      }
    }
  }
}
</script>

<style scoped>
.fieldMapImage {
  max-width: 1200px;
  margin: 0 auto;
}
</style>
