var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mobile ? 10 : 12}},[_c('v-card',{staticClass:"pa-0 ma-auto",style:(_vm.$vuetify.breakpoint.mobile ? 'max-width: 584px' : ''),attrs:{"width":"100%"}},[(!_vm.switched)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","md":_vm.$vuetify.breakpoint.mobile ? 0 : 6}},[_c('v-img',{staticClass:"border-radius-left",attrs:{"src":_vm.image,"width":"100%","aspect-ratio":""}})],1),_c('v-col',{class:_vm.textCss,attrs:{"cols":"12","md":_vm.$vuetify.breakpoint.mobile ? 0 : 6}},[_c('h1',{staticClass:"mt-10 px-8",class:{
          'mt-10': !_vm.criticlaWidth,
          'pl-8': !_vm.criticlaWidth,
        },domProps:{"innerHTML":_vm._s(_vm.title)}}),_c('p',{staticClass:"px-8",class:{
          'mt-5': true,
          'pl-8': !_vm.criticlaWidth,
          'pr-8': !_vm.criticlaWidth,
        },domProps:{"innerHTML":_vm._s(_vm.text)}}),_c('p',{staticClass:"pl-8 mb-10"},[_c('button',{staticClass:"mdButton",domProps:{"innerHTML":_vm._s(_vm.button)},on:{"click":function($event){return _vm.redirect(_vm.link)}}})])])],1):_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{class:_vm.textCss,attrs:{"cols":"12","md":_vm.$vuetify.breakpoint.mobile ? 0 : 6}},[_c('h1',{class:{
          'mt-10': !_vm.criticlaWidth,
          'pl-8': !_vm.criticlaWidth,
        },domProps:{"innerHTML":_vm._s(_vm.title)}}),_c('p',{class:{
          'mt-5': true,
          'pl-8': !_vm.criticlaWidth,
          'pr-8': !_vm.criticlaWidth,
        },domProps:{"innerHTML":_vm._s(_vm.text)}}),_c('p',{staticClass:"pl-8"},[_c('button',{staticClass:"mdButton",domProps:{"innerHTML":_vm._s(_vm.button)},on:{"click":function($event){return _vm.redirect(_vm.link)}}})])]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","md":"6"}},[_c('v-img',{staticClass:"border-radius-left",attrs:{"src":_vm.image,"eager":"","contain":"","width":"100%"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }