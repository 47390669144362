<template>
  <div :style="'background-color: ' + backgroundColor" :class="passedClass ? passedClass : ''">
    <v-row no-gutters justify="center">
      <v-col cols="11" md="8" class="pt-8">
        <p v-html="title" class="title-size white--text text-center"></p>
      </v-col>
    </v-row>
    <v-row no-gutters justify="center">
      <v-col cols="11" md="4" style="margin-top: -15px;">
        <p v-html="text" class="teaser-text text-size white--text text-center"></p>
      </v-col>
    </v-row>
    <v-row no-gutters justify="center">
      <v-col cols="11" md="8" class="pb-12 text-center">
        <button class="mdButton" v-html="buttonText" @click="open(link)"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "ConversionTeaserBig",
  props: ['backgroundColor', 'title', 'text', 'buttonText', 'link', 'passedClass'],
  methods: {
    open(link) {
      this.$router.push({name: link});
    }
  }
}
</script>

<style scoped>

</style>