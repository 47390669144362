<template>
  <div>
    <v-app-bar color="white" fixed height="71">
      <v-row v-if="!$vuetify.breakpoint.smAndDown" justify="center">
        <v-col md="10" class="max-width-1168">
          <v-row no-gutters class="pt-8">
            <v-col
              md="1"
              lg="2"
              :class="{
                'pt-2': $vuetify.breakpoint.md
              }"
            >
              <a @click="open('Home')">
                <img
                  :src="logo"
                  :alt="text.app.logo"
                  width="90"
                  class="d-inline-block"
                />
              </a>
            </v-col>
            <v-col md="11" lg="9"  class="pt-4 pb-4">
              <v-row no-gutters justify="center">
                <v-col
                    cols="2"
                    v-html="text.header.menu[4].text"
                    :class="
                    'text-center menu-text ' +
                      (activeMenu === 'Aktuelles' ? 'menu-text-active' : '')
                  "
                    @click="open('Aktuelles')"
                ></v-col>
                <v-col
                  cols="2"
                  v-html="text.header.menu[0].text"
                  :class="
                    'text-center menu-text ' +
                      (activeMenu === 'Standorte' ? 'menu-text-active' : '')
                  "
                  @click="open('Standorte')"
                ></v-col>
                <v-col
                  cols="4"
                  :class="
                    'text-center menu-text ' +
                      (openedSubmenu ||
                      activeMenu === 'McDrive' ||
                      activeMenu === 'Zukunftige' ||
                      activeMenu === 'Instore'
                        ? 'menu-text-active'
                        : '')
                  "
                  @click="
                    openedSubmenu = !openedSubmenu;
                    activeMenu = 'Restaurant';
                  "
                  >{{ text.header.menu[1].text }}
                  <v-icon v-if="!openedSubmenu">mdi-chevron-down</v-icon>
                  <v-icon v-else>mdi-chevron-up</v-icon>
                </v-col>
                <v-col
                  cols="2"
                  v-html="text.header.menu[2].text"
                  :class="
                    'text-center menu-text ' +
                      (activeMenu === 'Unternehmen' ? 'menu-text-active' : '')
                  "
                  @click="open('Unternehmen')"
                ></v-col>
                <v-col
                  cols="2"
                  v-html="text.header.menu[3].text"
                  :class="
                    'text-center menu-text ' +
                      (activeMenu === 'Kontakt' ? 'menu-text-active' : '')
                  "
                  @click="open('Kontakt')"
                ></v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-else justify="center">
        <v-col md="12">
          <v-row no-gutters>
            <v-col cols="1">
              <v-app-bar-nav-icon @click.stop="openDrawer"></v-app-bar-nav-icon>
            </v-col>
            <v-col cols="10" class="text-center">
              <a @click="open('Home')">
                <img
                  :src="logo"
                  :alt="text.app.logo"
                  width="109"
                  class="d-inline-block"
                />
              </a>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-expand-transition>
      <v-row
        class="opened-submenu"
        justify="center"
        v-show="openedSubmenu"
        no-gutters
      >
        <v-col md="11" lg="9" align-self="center">
          <v-row no-gutters justify="space-between">
            <v-col
              cols="4"
              class="pa-3 pointer text-center submenu-text"
              @click="open('McDrive')"
            >
              <v-img
                :src="image1"
                :max-width="submenuImagesWidth"
                class="d-inline-block"
                eager
              />
              <br />
              <p class="mt-5">{{ text.header.menu[1].submenu[0].text }}</p>
            </v-col>
            <v-col
              cols="4"
              class="pa-3 pointer text-center submenu-text"
              @click="open('Instore')"
            >
              <v-img
                :src="image2"
                :max-width="submenuImagesWidth"
                class="d-inline-block"
                eager
              />
              <br />
              <p class="mt-5">{{ text.header.menu[1].submenu[1].text }}</p>
            </v-col>
            <v-col
              cols="4"
              class="pa-3 pointer text-center submenu-text"
              @click="open('Zukunftige')"
            >
              <v-img
                :src="image3"
                :max-width="submenuImagesWidth"
                class="d-inline-block"
                eager
              />
              <br />
              <p class="mt-5">{{ text.header.menu[1].submenu[2].text }}</p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-expand-transition>
    <v-navigation-drawer v-model="drawer" fixed left temporary>
      <v-list nav dense>
        <v-list-item-group>
          <v-list-item>
            <v-list-item-title
                v-html="text.header.menu[4].text"
                @click="open('Aktuelles')"
            ></v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title
              v-html="text.header.menu[0].text"
              @click="open('Standorte')"
            ></v-list-item-title>
          </v-list-item>
          <v-expansion-panels accordion flat>
            <v-expansion-panel>
              <v-expansion-panel-header class="pl-0">
                <v-list-item>
                  <v-list-item-title
                    v-html="text.header.menu[1].text"
                  ></v-list-item-title>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-list-item>
                  <v-list-item-title
                    v-html="text.header.menu[1].submenu[0].text"
                    @click="open('McDrive')"
                  />
                </v-list-item>
                <v-list-item>
                  <v-list-item-title
                    v-html="text.header.menu[1].submenu[1].text"
                    @click="open('Instore')"
                  ></v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title
                    v-html="text.header.menu[1].submenu[2].text"
                    @click="open('Zukunftige')"
                  ></v-list-item-title>
                </v-list-item>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-list-item>
            <v-list-item-title
              v-html="text.header.menu[2].text"
              @click="open('Unternehmen')"
            ></v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title
              v-html="text.header.menu[3].text"
              @click="open('Kontakt')"
            ></v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import logo from "@/assets/img/0_Global/McDRE_Logo.svg";
import image1 from "@/assets/img/0_Global/MCD_Navi_McDrive.jpg";
import image2 from "@/assets/img/0_Global/MCD_Navi_Instore.jpg";
import image3 from "@/assets/img/0_Global/MCD_Navi_Zukunftskonzepte.jpg";

export default {
  name: "Header",
  data() {
    return {
      logo,
      activeMenu: null,
      openedSubmenu: false,
      image1,
      image2,
      image3,
      submenuImagesWidth: "100%"
    };
  },
  computed: {
    text() {
      return this.$store.state.text;
    },
    drawer: {
      get: function() {
        return this.$store.state.openDrawer;
      },
      set: function(newValue) {
        this.$store.commit("setOpenDrawer", newValue);
      }
    }
  },
  methods: {
    open(site) {
      this.activeMenu = site;
      this.drawer = false;
      // this.activeMenu = site;
      this.openedSubmenu = false;
      if (site !== this.$route.name) {
        this.$router.push({ name: site });
      }
    },
    openDrawer() {
      this.$store.commit("setOpenDrawer", !this.$store.state.openDrawer);
    },
    redirect() {
      window.location.replace("https://mcdonalds.de");
    },
  },
  mounted() {
    this.activeMenu = this.$route.name;
  },
  watch: {
    $route(to) {
      this.activeMenu = to.name;
    },
  },
};
</script>

<style scoped>
.menu-text {
  height: 39px;
  color: #2e2e2e;
  font-size: 20px;
  letter-spacing: -0.19px;
  line-height: 24px;
  cursor: pointer;
}

.menu-text-active {
  font-family: "Speedee Bold";
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  border-bottom: 4px solid #db0007;
  height: 39px;
}

.opened-submenu {
  position: fixed;
  top: 73px;
  left: 0;
  border-bottom: 1px solid #d6d6d6;
  width: 100%;
  /*height: 387px;*/
  z-index: 1000;
  background-color: #ffffff;
}

.submenu-text {
  color: #292929;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 22px;
}
</style>
