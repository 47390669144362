<template>
  <v-row no-gutters :justify="justifyContent ? justifyContent : 'center'"
  :class="passedClass ? passedClass : ''">
    <v-col cols="12" :md="intro ? 12 : 8" :class="{
      'pt-15': !removeIntoRopPadding,
      'pl-15 pr-15': !$vuetify.breakpoint.mobile,
      'pl-2 pr-2': $vuetify.breakpoint.mobile,
      }">
      <template>
        <h1 v-html="title"/>
        <p v-html="text" class="mt-5"/>
      </template>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "H1Intro",
  props: ['title', 'text', 'justifyContent', 'passedClass', 'intro', 'removeTopPadding'],
  computed: {
    removeIntoRopPadding() {
      return this.removeTopPadding
    }
  }
}
</script>

<style scoped>
h1 {
  font-family: "Speedee Bold";
  color: #292929;
  font-size: 54px;
  font-weight: bold;
  letter-spacing: -0.15px;
  line-height: 56px;
}

p {
  color: #2E2E2E;
  font-size: 24px;
  letter-spacing: -0.15px;
  line-height: 32px;
}
</style>