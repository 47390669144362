<template>
<v-row :class="passedClass ? passedClass : ''">
  <v-col cols="12" class="pt-6">
    <v-img :src="images" eager/>
  </v-col>
</v-row>
</template>

<script>
export default {
  name: "FullWidthImage",
  props: ['images', 'passedClass'],
}
</script>

<style scoped>

</style>