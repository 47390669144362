import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        lang: 'de',
        text: null,
        openDrawer: false,
        customSubject: null,
    },
    mutations: {
        setText(state, data) {
            state.text = data;
        },
        setOpenDrawer(state, data) {
            state.openDrawer = data;
        },
        setCustomSubject(state, data) {
            state.customSubject = data;
        },
    },
    actions: {},
    modules: {}
})
