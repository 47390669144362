<template>
  <v-row :class="passedClass + ($vuetify.breakpoint.smAndDown ? ' pb-0': '')" no-gutters justify="center">
    <v-col cols="12" md="10" class="max-width-1168">
      <v-col cols="12" :class="'text-center pa-0 ' + columnClass">
        <p v-html="title" class="mb-0 title-size"/>
        <v-row no-gutters justify="center" class="pt-10">
          <v-col cols="12" sm="4"
                 :class="{
            'pl-0 pr-2 pb-0': $vuetify.breakpoint.mdAndUp,
            'pl-2 pr-2 pt-7': $vuetify.breakpoint.smAndDown,
          }">
            <img :src="stamp1"  />
          </v-col>
          <v-col cols="12" sm="4" :class="{
            'pl-1 pr-1 pb-0': $vuetify.breakpoint.mdAndUp,
            'pl-2 pr-2 pt-7': $vuetify.breakpoint.smAndDown,
          }">
            <img :src="stamp2"/>
          </v-col>
          <v-col cols="12" sm="4" :class="{
            'pl-2 pr-0 pb-0': $vuetify.breakpoint.mdAndUp,
            'pl-2 pr-2 pt-7': $vuetify.breakpoint.smAndDown,
          }">
            <img :src="stamp3" />
          </v-col>
        </v-row>
        <v-row no-gutters justify="center" v-if="button">
          <v-col cols="3" sm="3" class="pa-3">
            <button class="mdButton" v-html="button" @click="open(link)"/>
          </v-col>
        </v-row>
      </v-col>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ThreeStamps",
  props: ['stamp1', 'stamp2', 'stamp3', 'title', 'passedClass', 'button', 'link', 'columnClass'],
  methods: {
    open(link) {
      window.open(link, "_blank");
    }
  }
}
</script>

<style scoped>

</style>
