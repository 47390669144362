<template>
  <v-row :class="passedClass ? passedClass : ''" justify="center">
    <v-col class="pa-0 max-width-1168">
      <v-row no-gutters class="justify-center">
        <v-col cols="12" v-if="cardsData.title" class="mb-6 text-center">
          <p v-html="cardsData.title" class="title-size"></p>
        </v-col>
        <v-col cols="12" v-html="cardsData.text" class="mb-6 mt-0 text-center">
          <p class="text-size" v-html="cardsData.text"/>
        </v-col>
      </v-row>
      <v-row class="flex-row justify-center" >
        <v-col :md="mdCoulmnNumbers" v-for="(item, i) in cardsData.items" :key="'cards_data_'+i"
               class="pa-2">
          <div :class="border ? 'tile-card ' : ''" style="height: 100%;">
            <v-img :src="images[i]" eager/>
            <div class="pa-10 d-flex flex-column">
              <v-card-title v-html="item.title"  v-if="item.title" class="pa-0 mb-4"/>
              <v-card-subtitle v-html="item.subtitle" v-if="item.subtitle"/>
              <v-card-text v-html="item.text" class="pa-0 mb-4"
                           v-if="item.text" />
              <button class="mdButton align-self-baseline" v-html="item.button"
                      @click="(!item.action) ? redirect(item.link, 1) : $emit('scrollTo', item.action)"
                      v-if="item.button"/>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "DynamicCards",
  props: ['cardsData', 'passedClass', 'images', 'border', 'backgrounds', 'noRadius', 'cardWidth', 'fz', 'height', 'text', 'passedTextClass', 'itemTextClass'],
  data: () => ({
    textStyles: '',
  }),
  methods: {
    redirect(link, inner) {
      if (!inner) {
        window.open(link, '_blank');
      } else {
        this.$router.push({name: link});
      }
    },
    calculateTextDivSizes() {
      if (this.passedTextClass) {
        let x = document.getElementsByClassName(this.passedTextClass);
        let elHeight = 0;
        for (let el of x) {
          if (elHeight < el.clientHeight) {
            elHeight = el.clientHeight;
          }
        }
        this.textStyles = 'height:' + (elHeight + 24) + 'px;';
      }
    },
  },
  mounted() {
    this.calculateTextDivSizes();
  },
  computed: {
    vCardTextCustomClass() {
      if (this.itemTextClass) {
        return this.itemTextClass;
      }
      return 'pl-10 pr-16 ';
    },
    mdCoulmnNumbers() {
      if (this.$vuetify.breakpoint.mobile) {
        return 0;
      }

      return this.cardWidth ? this.cardWidth : 4
    }
  }
}
</script>

<style scoped>
.tile-card {
  border: 1px solid #e4e4e4 !important;
}

.card-title, .v-card__title {
  font-family: "Speedee Bold";
  color: #292929;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: -0.14px;
  line-height: 25px;
  max-width: 420px;
  word-break: break-word;
}

.card-subtitle, .v-card__subtitle {
  width: 420px;
  color: #FFFFFF !important;
  font-size: 22px;
  font-style: italic;
  font-weight: bold;
  letter-spacing: -0.15px;
  line-height: 25px;
}

.card-text, .v-card__text {
  color: #292929;
  font-size: 16px;
  letter-spacing: -0.15px;
  line-height: 24px;
}

h1 {
  height: 40px;
  color: #292929;
  font-family: "Speedee Bold";
  font-size: 36px;
  font-weight: bold;
  letter-spacing: -0.15px;
  line-height: 40px;
  text-align: center;
}

.actionButtons {
  bottom: 0px;
}
</style>

