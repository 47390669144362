<template>
  <v-container fluid class="pa-0 pt-16">
    <Intro :image="intro"/>
    <HeroContent :title="text.heroContent.title"
                 :text="text.heroContent.text"
                 :button="text.heroContent.button"
                 :link="text.heroContent.link"
                 position="left"
                 :passed-class="'mt-16 pt-2 pb-0'"
                 :image="heroImage"/>
    <TwoColorDividers upper-color="#A9C141" lower-color="#D90007" passedClass="mt-16"/>
    <ThreeStamps :stamp1="stamp1" :stamp2="stamp2" :stamp3="stamp3" :title="threeStamps.title"
                 passedClass=" mt-12 mb-12 pb-4 "/>
    <ParagraphImageVertical :image="paragraphtVertical"/>
    <DynamicCards :cards-data="threeCards" :images="cardsImages" :border="true"
                  :passed-class="{
                    'mt-4 pt-6 mt-16 pb-6 mb-16':true,
                    'pr-5 pl-5': $vuetify.breakpoint.mobile
                  }"
                  passed-text-class="dynamicCards1"
    />
    <TwoColorDividers upper-color="#1F6437" lower-color="#D90007" :switched="true"/>
    <DynamicCards v-if="testimonials"
                  :cards-data="testimonials"
                  :images="testimoials"
                  passed-class="mt-16 pt-7 pb-16 mb-2"
                  :noRadius="true"
                  height="500"
                  fz="height: auto;"/>
    <TwoCardsNoImages :background-image="twoCardsBg" passed-class="mt-6" :data="twoCards"/>
    <FullWidthImage :images="fullWidthImage" passed-class="pl-5 pr-5 mt-16 pt-16"/>
    <HorizontalCardModule bg-color="#ffbc0d" passed-class="mt-10 pt-16 pb-16" :data="horizontalCardModule1"/>
    <TwoColorDividers upper-color="#1F6437" lower-color="#FFBC0D" passedClass="mb-10"/>
  </v-container>
</template>

<script>
import intro from '@/assets/img/1_Home/MCD_Home_Hero.jpg';
import heroImage from '@/assets/img/1_Home/MCD__Home_Big_Teaser_01.jpg';
import stamp1 from '@/assets/img/1_Home/Infographik/Mitarbeiter_2023.svg';
import stamp2 from '@/assets/img/1_Home/Infographik/Franchise_2023.svg';
import stamp3 from '@/assets/img/1_Home/Infographik/Restaurants_2023.svg';
import paragraphtVertical from "@/assets/img/1_Home/McD_Home_Fullscreenimages_01.jpg";
import card1 from "@/assets/img/0_Global/MCD_McDrive_Third_Teaser_01.jpg";
import card2 from "@/assets/img/0_Global/MCD_Instore_Third_Teaser_01.jpg";
import card3 from "@/assets/img/0_Global/MCD_FutureConcepts_Third_Teaser_01.jpg";
import testimonial1 from "@/assets/img/1_Home/Elements_Placeholder_Product Image 376x163.jpg";
import testimonial2 from "@/assets/img/1_Home/Elements_Placeholder_Product Image 376x163_1.jpg";
import testimonial3 from "@/assets/img/1_Home/Elements_Placeholder_Product Image 376x163_2.jpg";
import twoCardsBg from "@/assets/img/1_Home/MCD_Teaser_Background_01.jpg";
import fullWidthImage from "@/assets/img/1_Home/McD_Home_Fullscreencollage_01_new.jpg";

import Intro from "@/components/common/Intro";
import HeroContent from "@/components/common/HeroContent";
import TwoColorDividers from "@/components/common/TwoColorDividers";
import ThreeStamps from "@/components/common/ThreeStamps";
import ParagraphImageVertical from "@/components/common/ParagraphImageVertical";
import DynamicCards from "@/components/common/DynamicCards";
import TwoCardsNoImages from "@/components/common/TwoCardsNoImages";
import FullWidthImage from "@/components/common/FullWidthImage";
import HorizontalCardModule from "@/components/HorizontalCardModule";

export default {
  name: "HomePage",
  metaInfo: function () {
    return {
      title: this.text.metaTitle ? this.text.metaTitle : '',
      meta: [
        {name: 'description', content: this.text.metaDescription ? this.text.metaDescription : ''}
      ]
    }
  },
  components: {
    HorizontalCardModule,
    FullWidthImage,
    TwoCardsNoImages,
    DynamicCards,
    ParagraphImageVertical,
    ThreeStamps,
    TwoColorDividers,
    HeroContent,
    Intro,
  },
  data: () => ({
    intro,
    heroImage,
    stamp1,
    stamp2,
    stamp3,
    paragraphtVertical,
    cardsImages: [
      card1,
      card2,
      card3
    ],
    testimoials: [
      testimonial3,
      testimonial2,
      testimonial1
    ],
    twoCardsBg,
    fullWidthImage,
  }),
  mounted() {
    this.$store.commit('setCustomSubject', null);
  },
  computed: {
    text() {
      return this.$store.state.text.home
    },
    threeStamps() {
      return this.$store.state.text.home.threeStamps;
    },
    threeCards() {
      return this.$store.state.text.home.threeCards;
    },
    testimonials() {
      return this.$store.state.text.home.testimonials;
    },
    twoCards() {
      return this.$store.state.text.home.twoCards;
    },
    horizontalCardModule1() {
      return this.$store.state.text.home.horizontalCardModule1;
    },
    horizontalCardModule2() {
      return this.$store.state.text.home.horizontalCardModule2;
    }
  }
}
</script>

<style scoped>

</style>
