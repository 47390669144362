<template>
  <v-container fluid class="pa-0 pt-16 mt-12">
    <H1Intro v-if="text.h1intro"
             :title="text.h1intro.title" :text="text.h1intro.text"/>
    <ParagraphImageVertical :image="image" :text="text.imageParagraph.text"/>
    <TwoColorDividers lower-color="#1f6437" upper-color="#ffbc0d"
                      :passed-class="!$vuetify.breakpoint.xs ? 'mt-16 pt-11' : ''"/>
    <iframe :src="text.panoramaLink" width="100%" height="600"/>
    <ConversionTeaserBig background-color="#d90007" v-if="$store.state.text.modules.conversionTeaserBig"
                         :button-text="$store.state.text.modules.conversionTeaserBig.button"
                         :title="$store.state.text.modules.conversionTeaserBig.title"
                         :text="$store.state.text.modules.conversionTeaserBig.text"
    />
    <RestaurantKonzepte/>
    <TwoColorDividers upper-color="#a9c141" lower-color="#d90007"/>
  </v-container>
</template>

<script>
import H1Intro from "@/components/common/H1Intro";
import TwoColorDividers from "@/components/common/TwoColorDividers";
import ConversionTeaserBig from "@/components/common/ConversionTeaserBig";
import RestaurantKonzepte from "../common/RestaurantKonzepte";
import ParagraphImageVertical from "../common/ParagraphImageVertical";

export default {
  name: "PageWithPanorama",
  props: ['moduleName', 'threeCards'],
  components: {
    ParagraphImageVertical,
    RestaurantKonzepte,
    ConversionTeaserBig,
    TwoColorDividers,
    H1Intro,
  },
  metaInfo: function () {
    return {
      title: this.text.metaTitle ? this.text.metaTitle : '',
      meta: [
        {name: 'description', content: this.text.metaDescription ? this.text.metaDescription : ''}
      ]
    }
  },
  mounted() {
    this.$store.commit('setCustomSubject', null);
  },
  data: () => ({}),
  computed: {
    image() {
      if (this.text.imageParagraph.image) {
        return this.text.imageParagraph.imgage;
      }
      return null;
    },
    text() {
      return this.$store.state.text[this.moduleName];
    },
    galleryData() {
      return this.$store.state.text[this.gallery];
    }
  }
}
</script>

<style scoped>

</style>