<template>
  <v-row no-gutters :class="'main-row ' + (passedClass ? passedClass : '')" justify="center">
    <v-col cols="12" md="10" class="pa-0">
      <v-row no-gutters justify="center">
        <v-img :src="backgroundImage" eager class="backgroundImage" width="100%"
               :height="!$vuetify.breakpoint.mobile ? '132%' : '120%'"/>
        <v-col cols="12" class="internal text-center pt-8 max-width-1168">
          <p v-html="data.title" v-if="data.title" class="pt-16 title-size white--text"/>
          <v-row justify="center" class="pt-7">
            <v-col cols="10" :md="$vuetify.breakpoint.mobile ? 0 : 6" v-for="(card, i) in data.items" :key="'twocards_'+i">
              <v-card class="px-10 py-4 whiteCard" >
                <v-card-text class="text-left pl-15" >
                  <v-list-item v-for="(item, i) in card.item" :key="item.title + i" class="v-list-item">
                    <v-list-item-avatar class="v-list-item__avatar">
                      <v-icon large color="#FFBC0D" class="icon-class">mdi-check-circle</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="item.title" class="text-wrap"></v-list-item-title>
                      <v-list-item-subtitle v-html="item.text"></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "TwoCardsNoImages",
  props: ['backgroundImage', 'passedClass', 'data', 'title'],
  data: ()=>({
    whiteCardStyles: '',
  }),
  mounted() {
      let x = document.getElementsByClassName('whiteCard');
      let elHeight = 0;
      for (let el of x) {
        if (elHeight < el.clientHeight) {
          elHeight = el.clientHeight;
        }
      }
      this.whiteCardStyles = 'height:' + (elHeight + 20) + 'px;';
  }
}
</script>
<style scoped>
.main-row {
  position: relative;
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  overflow: hidden;
}

.internal {
  position: relative;
  z-index: 2;
}

.v-list-item {
  position: relative;
}

.v-list-item__avatar {
  position: absolute;
  top: 0px;
  left: -2.5em;
}

.v-list-item__subtitle {
  white-space: unset;
  width: 413px;
  color: #292929;
  font-size: 16px;
  letter-spacing: -0.15px;
  line-height: 24px;
}

.v-list-item__title {
  width: 410px;
  color: #292929;
  font-size: 25px;
  font-weight: bold;
  letter-spacing: -0.14px;
  line-height: 25px;
  text-overflow: unset;
  word-break: normal !important;
}

h1 {
  color: #FFFFFF;
  font-size: 36px;
  font-weight: bold;
  letter-spacing: -0.15px;
  line-height: 40px;
}

.icon-class {
  background-color: black;
  width: 25px;
  height: 25px;
}
</style>
