<template>
  <v-row no-gutters :style="{backgroundColor: bgColor}" justify="center" :class="passedClass ? passedClass : ''">
    <v-col cols="12" md="9" class="pt-11 pb-11 max-width-1168">
      <HorizontalCard :image="imageData ? imageData : image"
                      :title="data.title"
                      :text="data.text"
                      :button="data.button"
                      :link="data.link"
                      :switched="switched"
      />
    </v-col>
  </v-row>
</template>

<script>
import image from "@/assets/img/0_Global/McD_Teaser_Contact.jpg";
import HorizontalCard from "@/components/common/HorizontalCard";

export default {
  name: "HorizontalCardModule",
  components: {HorizontalCard},
  props: ['bgColor', 'passedClass', 'data', 'switched', 'imageData'],
  data: () => ({
    image
  }),
}
</script>

<style scoped>

</style>
